/* global $ */
import Swiper from 'swiper/bundle';
import 'swiper/css/bundle';

class App {
    bootstrap;
    resizeCallbacks = [];

    constructor() {
        this.updateWindowProperties();
        const updateProps = () => {
            document.documentElement.style.setProperty(
                "--window-width",
                document.body.clientWidth + "px"
            );
            document.documentElement.style.setProperty(
                "--window-height",
                window.innerHeight + "px"
            );
            this.resizeCallbacks.forEach((callback) => callback());
        };
        window.onresize = updateProps;

        this.disableLogger();
        this.getBootstrap();
        this.initTooltips($('[data-bs-toggle="tooltip"]'));
        this.initToasts($(".toast"));
        this.initForms($("form"));
        this.initMatrixBlocks();
        this.initStickyHeader();
        this.initShareIcons();
        this.initLatestSlider();
        this.initFileUpload();
        this.setPropertyAddress();
        $('.contactTeam').click(this.contactTeam);

        $("body").css("opacity", 1);
    }

    addResizeCallback(callback) {
        this.resizeCallbacks.push(callback);
    }

    setPropertyAddress()
    {
        if ($('.interest-form').length) {
            let property = $('.interest-form').data('property');
            if (property != '') {
                $('#note-of-interest-field-property-address-input').val(property);
            }
        }
    }

    initLatestSlider() {
        new Swiper(".latest-slider", {
            loop: true,
            slidesPerView: 1,
            spaceBetween: 20,
            speed: 1000,
            autoplay: {
                delay: 5000,
                disableOnInteraction: false,
            },
            navigation: {
                nextEl: '.swiper-nav .next-btn',
                prevEl: '.swiper-nav .prev-btn',
            },
            breakpoints: {
                768: {
                    spaceBetween: 30,
                    slidesPerView: 2
                },
                992: {
                    spaceBetween: 47,
                    slidesPerView: 2
                }
            }
        });
    }

    updateWindowProperties() {
        document.documentElement.style.setProperty(
            "--window-width",
            document.body.clientWidth + "px"
        );

        document.documentElement.style.setProperty(
            "--window-height",
            window.innerHeight + "px"
        );
    }

    initStickyHeader() {
        var _this = this;
        var ofv = [];
        $(document).scroll(() => {
            if ($('.team-body').length) {
                var maxlength = $('#team_feed').attr('max-length');
                var offset = $('#team_feed').attr('current-length');
                if (offset < parseInt(maxlength)) {
                    let newvalue = parseInt(offset) + 3;
                    if ($.inArray(newvalue, ofv) === -1) {
                        ofv.push(newvalue);
                        $('#team_feed').attr('current-length', newvalue);
                        _this.loadMore(newvalue);
                    }
                }
            }
            if ($('.latest-body').length) {
                var latestmaxlength = $('#latest_feed').attr('max-length');
                var latestoffset = $('#latest_feed').attr('current-length');
                if (latestoffset < parseInt(latestmaxlength)) {
                    let newvalue = parseInt(latestoffset) + 4;
                    if ($.inArray(newvalue, ofv) === -1) {
                        ofv.push(newvalue);
                        $('#latest_feed').attr('current-length', newvalue);
                        _this.loadMoreLatest(newvalue);
                    }
                }
            }
            stickyNav();
        });

        var stickyNav = function () {
            let bannerheight = $('.header-banner').height();
            if ($('.sticky-buttons').length) {
                bannerheight = $('.header-banner').height() + $('.sticky-buttons').height();
            }
            if ($(window).scrollTop() > bannerheight) {
                $("#header").addClass("scrolled");
                $('.scroll-overlay').removeClass('position-absolute').addClass('position-fixed');
            } else {
                $("#header").removeClass("scrolled");
                $('.scroll-overlay').removeClass('position-fixed').addClass('position-absolute');
            }
            if ($('.scroll-overlay').length) {
                if ($(window).scrollTop() > $('.header-banner').height()) {
                    $('.scroll-overlay').css('z-index', '9');
                } else {
                    $('.scroll-overlay').css('z-index', '-1');
                }
            }
        };

        stickyNav();

        this.getBootstrap().then((bootstrap) => {
            $(".menu-toggler").on("click", function () {
                if ($("#search-menu").hasClass("show")) {
                    new bootstrap.Collapse("#search-menu").hide();
                }
                if ($(this).hasClass("collapsed")) {
                    $('body').removeClass('overflow-hidden');
                } else {
                    $('body').addClass('overflow-hidden');
                }
                _this.updateWindowProperties();
            });
            $(document).click(function(e) {
                if (!$(e.target).parents().hasClass('collapse-menus')) {
                    if ($("#main-menu").hasClass("show")) {
                        new bootstrap.Collapse("#main-menu").hide();
                        $('body').removeClass('overflow-hidden');
                    }
                    if ($("#search-menu").hasClass("show")) {
                        new bootstrap.Collapse("#search-menu").hide();
                    }
                }
            });
            $(document).on('keydown', function(event) {
                if (event.key == "Escape") {
                    if ($("#main-menu").hasClass("show")) {
                        new bootstrap.Collapse("#main-menu").hide();
                        $('body').removeClass('overflow-hidden');
                    }
                    if ($("#search-menu").hasClass("show")) {
                        new bootstrap.Collapse("#search-menu").hide();
                    }
                }
            });
        });
    }

    getBootstrap() {
        if (!this.bootstrap) {
            this.bootstrap = import(
                /* webpackChunkName: "bootstrap" */ "bootstrap"
            );
        }
        return this.bootstrap;
    }

    disableLogger() {
        // if (window.Globals.env == "production") {
        //     console.log = function () {};
        // }
    }

    loadMore(newvalue) {
        var offset = $('#team_feed').attr('current-length');
        var limit = 3;
        var _this = this;
        //console.log(ofv[ofv.length - 1]);
        $.ajax({
            url: '/getMembers',
            method: 'get',
            data: {
                offset: newvalue - 3,
                limit: limit
            }
        }).done((data) => {
            let newvalue = parseInt(offset) + parseInt(limit);
            $('#team_feed').attr('current-length', newvalue);
            let articles = $(data);
            $('#team_feed').append(articles);
            $('.contactTeam').click(_this.contactTeam);
        });
    }

    loadMoreLatest(newvalue) {
        let catId = $("select[name='category']").val();
        let subject = $("input[name='subject']").val();
        var offset = $('#article_feed').attr('current-length');
        var limit = 4;
        //console.log(ofv[ofv.length - 1]);

        $.ajax({
            url: '/getLatests',
            method: 'get',
            data: {
                offset: newvalue - 4,
                limit: limit,
                catId: catId,
                subject: subject
            }
        }).done((data) => {
            let newvalue = parseInt(offset) + parseInt(limit);
            $('#latest_feed').attr('current-length', newvalue);
            let articles = $(data);
            $('#latest_feed').append(articles);
        });
    }

    initForms($forms) {
        if ($forms.length) {
            import(/* webpackChunkName: "form" */ "./components/form").then(
                (chunk) => {
                    $.each($forms, (i, elem) => {
                        new chunk.Form($(elem));
                    });
                }
            );
        }
    }

    initMatrixBlocks() {
        if ($(".matrix-block").length) {
            import(
                /* webpackChunkName: "matrixContent" */ "./components/matrixContent"
            );
        }
    }

    initToasts($elems) {
        if ($elems.length) {
            this.getBootstrap().then((bootstrap) => {
                $.each($elems, function (i, elem) {
                    new bootstrap.Toast(elem).show();
                });
            });
        }
    }

    initTooltips($elems) {
        if ($elems.length) {
            this.getBootstrap().then((bootstrap) => {
                $.each($elems, function (i, elem) {
                    new bootstrap.Tooltip(elem);
                });
            });
        }
    }

    addAlert(message, $elem, type = "danger", dismissible = false) {
        let _class = "alert alert-" + type;
        if (dismissible) {
            _class += " alert-dismissible fade show";
            message +=
                '<button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>';
        }
        $('<div class="' + _class + '">' + message + "</div>").appendTo($elem);
    }

    addAlerts(messages, $elem, type = "danger", dismissible = false) {
        for (let i in messages) {
            this.addAlert(messages[i], $elem, type, dismissible);
        }
    }

    handleURLParams(paramNames = [], expirationTime = 0) {
        const url = new URL(window.location.href);
        const params = new URLSearchParams(url.search);

        if (paramNames.length === 0) return;

        paramNames.forEach((param) => {
            if (params.has(param)) {
                const value = params.get(param);
                if (expirationTime !== 0) {
                    const expiry = Date.now() + expirationTime * 1000;
                    localStorage.setItem(
                        param,
                        JSON.stringify({ value, expiry })
                    );
                } else {
                    localStorage.setItem(param, value);
                }
            }
        });
    }

    getStoredParams(paramNames = []) {
        const result = {};

        paramNames.forEach((param) => {
            const storedValue = localStorage.getItem(param);
            if (storedValue) {
                try {
                    const parsedValue = JSON.parse(storedValue);
                    if (parsedValue.expiry && parsedValue.expiry < Date.now()) {
                        localStorage.removeItem(param);
                    } else {
                        result[param] = parsedValue.value || storedValue;
                    }
                } catch (e) {
                    result[param] = storedValue;
                }
            }
        });

        return result;
    }

    initShareIcons() {
        if ($(".share-icons").length) {
            $(".share-icons").on("click", function () {
                $(this).addClass('show');
            }).on("mouseleave", function () {
                $(this).removeClass('show');
            });
        }
    }

    initFileUpload() {
        $('.file-upload').each(function() {
            let input = $(this).find('input');
            let label = $(this).find('.btn');
            input.change(() => {
                let file = input.val().split("\\").pop();
                if(file.length > 25){
                    file = file.substr(0,22) + "...";
                }
                label.html(file);
            });
        });
    }

    contactTeam(e)
    {
        e.preventDefault();

        const teamId = $(this).data('team');

        $('#contactSection').get(0).scrollIntoView();
        $('#contact-team-member-field-team-member-input').val(teamId);
    }
}

export default App;
